import FoodTrackerAPI from '../api'

export default function getAdmin({ token, sub }) {
  return async (dispatch) => {
    try {
      const admin = await FoodTrackerAPI.getAdmin({
        token,
        sub,
      })
      dispatch({ type: 'ADMIN_UPLOADED', admin })

      return true
    } catch (error) {
      dispatch({ type: 'ADMIN_UPLOAD_FAILED' })
      return false
    }
  }
}
