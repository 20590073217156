import FoodTrackerAPI from '../api'

export default function createUser({ token, sub, name }) {
  return async (dispatch) => {
    try {
      await FoodTrackerAPI.createUser({ token, sub, name })
      return true
    } catch (error) {
      dispatch({ type: 'USER_CREATION_FAILED' })
      return false
    }
  }
}
