import _camelCase from 'lodash.camelcase'
import React, { useState } from 'react'
import ProgressBar from './ProgressBar'
import Modal from '../../Modal'

const UserLimits = ({
  user,
  caloriesConsumedToday,
  moneySpentThisMonth,
  changeUserLimit,
  sub,
  token,
}) => {
  const [showLimitModal, setShowLimitModal] = useState(false)
  const [selectedLimit, setSelectedLimit] = useState('Daily Calorie Limit')
  const [limitValue, setLimitValue] = useState(user.dailyCalorieLimit)
  const onClickChangeLimit = () => {
    changeUserLimit({
      sub,
      token,
      limitChange: {
        type: selectedLimit.split(' ').join(''),
        value: limitValue,
      },
    })
    setShowLimitModal(false)
  }

  const changeLimitModal = () => (
    <Modal showModal={showLimitModal} setShowModal={setShowLimitModal}>
      <div className="UserLimitModalContentForm">
        <label>Change {selectedLimit}</label>

        <input
          type="number"
          value={limitValue}
          onChange={(e) => setLimitValue(Number(e.target.value))}
        ></input>
        <button type="submit" onClick={onClickChangeLimit}>
          Change Limit
        </button>
      </div>
    </Modal>
  )
  const Limit = ({ limit, user, progress = 0 }) => {
    return (
      <div className="UserLimit">
        <h2
          onClick={() => {
            setShowLimitModal(true)
            setSelectedLimit(limit)
            setLimitValue(user[_camelCase(limit)])
          }}
        >
          {limit}
        </h2>
        <ProgressBar
          progress={progress}
          total={user[_camelCase(limit)]}
          backgroundColor="orange"
        />
      </div>
    )
  }
  return (
    <div className="UserLimits">
      {changeLimitModal()}
      <Limit
        limit="Daily Calorie Limit"
        user={user}
        progress={caloriesConsumedToday}
      />
      <Limit
        limit="Monthly Spending Limit"
        user={user}
        progress={moneySpentThisMonth}
      />
    </div>
  )
}
export default UserLimits
