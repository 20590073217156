import { useAuth0 } from '@auth0/auth0-react'

const LoginPage = ({}) => {
  const { loginWithRedirect } = useAuth0()

  const Icon = () => (
    <div className="LoginBoxIconDiv">
      <img className="LoginBoxIconDivImg" src={`fruit.png`} alt="coder logo" />
    </div>
  )

  const LoginButton = () => (
    <button className="LoginBoxButton" onClick={() => loginWithRedirect()}>
      Log In
    </button>
  )

  return (
    <div className="Login">
      <div className="LoginBox">
        <h2>Food Tracker</h2>
        <Icon />
        <LoginButton />
      </div>
    </div>
  )
}

export default LoginPage
