import React, { useState } from 'react'
import Modal from './Modal'
import EntryForm from './EntryForm'

const Entries = ({
  entries,
  sub,
  token,
  deleteEntry,
  createEntry,
  editEntry,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [entryToDelete, setEntryToDelete] = useState({ foodName: '' })

  const [entryToEdit, setEntryToEdit] = useState({
    entryId: '',
  })
  const EntriesHeader = () => (
    <div className="EntriesHeader">
      <h2>Food Entries</h2>
      <div className="EntriesHeaderIcon">
        <img
          className="EntriesHeaderIconImg"
          src={`add.png`}
          alt="add logo"
          onClick={() => setShowModal(true)}
        />
      </div>
    </div>
  )

  const onClickAddEntryThunk = (foodName, price, calories, date) => {
    const onClickAddEntry = () => {
      createEntry({
        token,
        entry: {
          sub,
          name: foodName,
          price,
          calories,
          date,
        },
      })
      setShowModal(false)
    }
    return onClickAddEntry
  }

  const onClickEditEntryThunk = (foodName, price, calories, date) => {
    const onClickEditEntry = () => {
      editEntry({
        token,
        entryId: entryToEdit.entryId,
        entryEdit: {
          sub,
          name: foodName,
          price,
          calories,
          date,
        },
      })
      setShowEditModal(false)
    }
    return onClickEditEntry
  }

  const DeleteEntryModal = () => (
    <Modal showModal={showDeleteModal} setShowModal={setShowDeleteModal}>
      <div>
        <h2>Are you sure you want to delete entry {entryToDelete.name}?</h2>
        <img
          className="DeleteEntryIconImg"
          src={`bin.png`}
          alt="coder logo"
          onClick={() => {
            deleteEntry({ sub, token, entry: entryToDelete })
            setShowDeleteModal(false)
          }}
        />
      </div>
    </Modal>
  )
  const AddEntryModal = () => (
    <Modal showModal={showModal} setShowModal={setShowModal}>
      <EntryForm onClickThunk={onClickAddEntryThunk} action="add" />
    </Modal>
  )

  const EditEntryModal = () => (
    <Modal showModal={showEditModal} setShowModal={setShowEditModal}>
      <EntryForm
        onClickThunk={onClickEditEntryThunk}
        action="edit"
        entry={entryToEdit}
      />
    </Modal>
  )

  const titles = ['Name', 'Calories', 'Price', 'Date', 'Time', 'Delete', 'Edit']

  return (
    <div className="EntriesSection">
      {AddEntryModal()}
      {EditEntryModal()}
      {DeleteEntryModal()}
      <EntriesHeader />

      <div className="EntriesList">
        <div className="Entry">
          {titles.map((title) => (
            <h4 className="EntryProperty">{title}</h4>
          ))}
        </div>
        {entries.map((entry) => (
          <div className="Entry">
            <p className="EntryProperty">{entry.name}</p>
            <p className="EntryProperty">{entry.calories}</p>
            <p className="EntryProperty">{entry.price}</p>
            <p className="EntryProperty">
              {new Date(entry.date).toLocaleDateString()}
            </p>
            <p className="EntryProperty">
              {new Date(entry.date).toLocaleTimeString()}
            </p>
            <div className="EntryIcon">
              <img
                className="EntryIconImg"
                src={`bin.png`}
                alt="coder logo"
                onClick={() => {
                  setEntryToDelete(entry)
                  setShowDeleteModal(true)
                }}
              />
            </div>
            <div className="EntryIcon">
              <img
                onClick={() => {
                  setEntryToEdit(entry)
                  setShowEditModal(true)
                }}
                className="EntryIconImg"
                src={`edit.png`}
                alt="coder logo"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Entries
