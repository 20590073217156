import { connect } from 'react-redux'
import Entries from '../components/Entries'
import deleteEntry from '../actions/deleteEntry'
import createEntry from '../actions/createEntry'
import editEntry from '../actions/editEntry'

const mapStateToProps = (state) => {
  const { entries } = state.entries
  const { token, sub } = state.app

  return {
    entries,
    token,
    sub,
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteEntry: ({ token, sub, entry }) =>
    dispatch(deleteEntry({ token, sub, entry, admin: false })),
  createEntry: ({ token, entry }) =>
    dispatch(createEntry({ token, entry, admin: false })),
  editEntry: ({ token, entryId, entryEdit }) =>
    dispatch(editEntry({ token, entryId, entryEdit, admin: false })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(Entries)

export default retVal
