const axios = require('axios')
const validateStatus = () => true // Don't throw HTTP exceptions. Delegate to the tests to decide which error is acceptable
const axiosClient = axios.create({
  baseURL: `https://api-foodtracker.robros.org`,
  validateStatus,
})

const setAuthHeader = (token) =>
  (axiosClient.defaults.headers.common.Authorization = `Bearer ${token}`)

const getUser = async ({ sub, token }) => {
  setAuthHeader(token)
  const response = await axiosClient.get(`/users/${sub}/entries`)
  if (response.status === 200) return response.data

  throw new Error('No User Found')
}

const deleteUser = async ({ sub, token }) => {
  setAuthHeader(token)
  const response = await axiosClient.delete(`/users/${sub}`)
  if (response.status === 201) return response.data

  throw new Error('No User Found')
}

const deleteEntry = async ({ sub, token, entry: { entryId } }) => {
  setAuthHeader(token)
  const response = await axiosClient.delete(`/users/${sub}/entries/${entryId}`)
  if (response.status === 201) return true

  throw new Error('Unable to Delete Entry')
}
const editEntry = async ({ sub, token, entryId, entryEdit }) => {
  setAuthHeader(token)
  const response = await axiosClient.put(
    `/users/${sub}/entries/${entryId}`,
    entryEdit,
  )
  if (response.status === 200) return response.data

  throw new Error('Unable to Edit Entry')
}
const createUser = async ({ sub, token, name }) => {
  setAuthHeader(token)

  const user = {
    sub,
    token,
    name,
    dailyCalorieLimit: 2100,
    monthlySpendingLimit: 1000,
  }

  const response = await axiosClient.post(`/users`, user)
  if (response.status !== 201) throw Error(response.data)
  return response.data
}

const createEntry = async ({ entry, token }) => {
  setAuthHeader(token)

  const response = await axiosClient.post(`/entries`, entry)
  if (response.status === 200) return response.data
  throw new Error('Unable to create entry')
}

const changeUserLimit = async ({ sub, token, limitChange }) => {
  setAuthHeader(token)

  const response = await axiosClient.post(
    `/users/${sub}/limit:change`,
    limitChange,
  )
  if (response.status === 200) return response.data
  throw new Error('Unable to create entry')
}

const getAdmin = async ({ sub, token }) => {
  setAuthHeader(token)
  const response = await axiosClient.get(`/admins/${sub}/report`)
  if (response.status === 200) return response.data

  throw new Error('No User Found')
}

const FoodTrackerAPI = {
  getUser,
  createUser,
  deleteEntry,
  createEntry,
  changeUserLimit,
  editEntry,
  deleteUser,
  getAdmin,
}

export default FoodTrackerAPI
