import LogOutButton from '../LogOutButton'
import React, { useEffect, useState } from 'react'
import Entries from '../Entries'
import { Link } from 'react-router-dom'
import Modal from '../Modal'

const Admin = ({
  numberOfEntriesInLastWeek,
  numberOfEntriesWeekBeforeLast,
  userReports,
  getAdmin,
  token,
  sub,
  adminUploadFailed,
  deleteEntry,
  createEntry,
  editEntry,
  deleteUser,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [userToDelete, setUserToDelete] = useState({ sub: '' })

  const DeleteModal = () => (
    <Modal showModal={showDeleteModal} setShowModal={setShowDeleteModal}>
      <div>
        <h2>Are you sure you want to delete user {userToDelete.sub}?</h2>
        <button
          className="UserReportsDeleteUser"
          onClick={() => {
            deleteUser({ token, ...userToDelete })
            setUserToDelete({ sub: '' })
            setShowDeleteModal(false)
          }}
        >
          DELETE USER AND THEIR ENTRIES
        </button>
      </div>
    </Modal>
  )
  useEffect(() => {
    getAdmin({ token, sub })
  }, [])

  const UserHeader = () => {
    return (
      <div className="AdminHeader">
        <Link to="/">
          <img className="AdminHeaderLogo" src={`fruit.png`} alt="fruit logo" />
        </Link>
        <LogOutButton className="AdminLogout" />
      </div>
    )
  }

  const AdminReports = () => {
    if (!userReports.length) {
      return <></>
    }

    const User = ({ user }) => {
      const { name } = user

      const keys = Object.keys(user).filter((key) => key !== 'name')

      const titles = keys.map((key) => (
        <h5 className="UserReportUserTitlesTitle">{key.toUpperCase()}</h5>
      ))
      const values = keys.map((key) => (
        <p className="UserReportUserTitlesTitle">{user[key]}</p>
      ))
      return (
        <div>
          <div className="UserReportUser">
            <h3>{name}</h3>

            <div className="UserReportUserColumns">{titles}</div>
            <div className="UserReportUserColumns">{values}</div>
          </div>
        </div>
      )
    }

    return (
      <div className="UserReports">
        {DeleteModal()}
        {userReports.map((report) => (
          <div className="UserReport">
            <User {...report} />
            <Entries
              {...report}
              sub={report.user.sub}
              token={token}
              deleteEntry={deleteEntry}
              editEntry={editEntry}
              createEntry={createEntry}
            />
            <button
              className="UserReportsDeleteUser"
              onClick={() => {
                setUserToDelete(report.user)
                setShowDeleteModal(true)
              }}
            >
              DELETE USER
            </button>
            <hr />
          </div>
        ))}
      </div>
    )
  }

  const TotalStats = () => (
    <div class="TotalStatsDiv">
      <h1>Total Number of Entries</h1>
      <div class="TotalStatsDivStats">
        <h2>In The Last Week: {numberOfEntriesInLastWeek}</h2>
        <h2>In The Week Before: {numberOfEntriesWeekBeforeLast}</h2>
      </div>
    </div>
  )

  const UnauthorizedError = () => (
    <div className="UnauthorizedUser">
      <h1>Unauthorized. You must be an admin to view this page.</h1>
      <img className="UnauthorizedImage" src={`warning.png`} alt="coder logo" />
    </div>
  )
  if (adminUploadFailed) {
    return (
      <div className="UnauthorizedUser">
        <h1>Unauthorized. You must be an admin to view this page.</h1>
        <img
          className="UnauthorizedImage"
          src={`warning.png`}
          alt="coder logo"
        />
      </div>
    )
  }

  const body = adminUploadFailed ? (
    <UnauthorizedError />
  ) : (
    <>
      <TotalStats />

      <AdminReports />
    </>
  )
  return (
    <div>
      <UserHeader />
      {body}
    </div>
  )
}

export default Admin
