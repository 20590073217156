import _camelCase from 'lodash.camelcase'
const user = (
  state = {
    user: {
      name: '',
      monthlySpendingLimit: undefined,
      dailyCalorieLimit: undefined,
      sub: '',
    },
  },
  action,
) => {
  switch (action.type) {
    case 'USER_UPLOADED': {
      return Object.assign({}, state, { user: action.user })
    }
    case 'USER_UPLOAD_FAILED': {
      return state
    }

    case 'USER_LIMIT_CHANGED': {
      const { type, value } = action.limitChange
      const key = _camelCase(type)
      return Object.assign({}, state, { user: { ...state.user, [key]: value } })
    }

    case 'USER_DELETED': {
      return Object.assign({}, state, {
        user: {
          name: '',
          monthlySpendingLimit: undefined,
          dailyCalorieLimit: undefined,
          sub: '',
        },
      })
    }

    default: {
      return state
    }
  }
}

export default user
