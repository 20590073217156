import React, { useEffect, useState } from 'react'
import LogOutButton from '../LogOutButton'
import Loading from '../Loading'
import UserLimits from '../../containers/UserLimits'
import Entries from '../../containers/Entries'
import { Link } from 'react-router-dom'
const User = ({ token, sub, user, getUser, getAdmin }) => {
  const [showAdminButton, setShowAdminButton] = useState(false)
  useEffect(() => {
    const checkForUser = async () => {
      const userExists = await getUser({ token, sub })
      if (!userExists) window.location = '/signup'
    }

    if (!(token || sub)) {
      window.location = '/'
    }
    if (!user.name) {
      checkForUser()
    }
  })

  useEffect(() => {
    const checkIfUserIsAdmin = async () => {
      const isAdmin = await getAdmin({ token, sub })
      setShowAdminButton(isAdmin)
    }
    checkIfUserIsAdmin()
  }, [])

  const UserHeader = () => {
    const HeaderButtons = () => (
      <div className="UserHeaderButtons">
        {showAdminButton ? (
          <Link to="/admin">
            <img
              className="UserHeaderButton"
              src={`admin.png`}
              alt="coder logo"
            />
          </Link>
        ) : (
          <></>
        )}
        <LogOutButton className="UserHeaderButton" />
      </div>
    )
    return (
      <div className="UserHeader">
        <Link to="/">
          <img className="UserHeaderLogo" src={`fruit.png`} alt="coder logo" />
        </Link>

        <div className="UserHeaderTitle">
          <h1>Food Tracker</h1>
          <p className="UserHeaderName">{user.name}</p>
        </div>
        <div className="UserHeaderLogout">
          <HeaderButtons />
        </div>
      </div>
    )
  }

  const UserContent = () => {
    return (
      <div className="UserContent">
        <UserLimits />

        <Entries />
      </div>
    )
  }

  if (!user.name) {
    return <Loading message="Looking for user" />
  }

  return (
    <div className="UserPage">
      <UserHeader />

      <UserContent />
    </div>
  )
}

export default User
