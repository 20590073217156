import FoodTrackerAPI from '../api'

export default function createEntry({ token, entry, admin = false }) {
  return async (dispatch) => {
    try {
      const createdEntry = await FoodTrackerAPI.createEntry({ token, entry })
      dispatch({ type: 'ENTRY_CREATED', entry: createdEntry, admin })

      return true
    } catch (error) {
      dispatch({ type: 'USER_CREATION_FAILED' })
      return false
    }
  }
}
