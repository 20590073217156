import FoodTrackerAPI from '../api'

export default function deleteEntry({ token, sub, entry, admin = false }) {
  return async (dispatch) => {
    try {
      await FoodTrackerAPI.deleteEntry({ token, sub, entry })
      dispatch({ type: 'ENTRY_DELETED', deletedEntry: entry, admin })
      return true
    } catch (error) {
      dispatch({ type: 'ENTRY_DELETION_FAILED' })
      return false
    }
  }
}
