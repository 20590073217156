import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const LogoutButton = ({ className }) => {
  const { logout } = useAuth0()

  return (
    <img
      className={className}
      onClick={() => logout({ returnTo: window.location.origin })}
      src={`logout.png`}
      alt="coder logo"
    />
  )
}

export default LogoutButton
