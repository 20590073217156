import { connect } from 'react-redux'
import UserLimits from '../components/pages/Limits/UserLimits'
import changeUserLimit from '../actions/changeUserLimit'

const mapStateToProps = (state) => {
  const { token, sub } = state.app
  const { user } = state.user
  const { caloriesConsumedToday, moneySpentThisMonth } = state.entries
  return {
    token,
    sub,
    user,
    caloriesConsumedToday,
    moneySpentThisMonth,
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeUserLimit: ({ token, sub, limitChange }) =>
    dispatch(changeUserLimit({ token, sub, limitChange })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(UserLimits)

export default retVal
