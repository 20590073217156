const getRelevantDates = () => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth()
  const firstDayOfMonth = new Date(y, m, 1)

  return { firstDayOfMonth, yesterday }
}

const sortEntries = (entries) =>
  entries.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
const entries = (
  state = {
    entries: [],
    weekEntries: [],
    monthEntries: [],
    caloriesConsumedToday: 0,
    moneySpentThisMonth: 0,
  },
  action,
) => {
  if (action.admin) return state

  switch (action.type) {
    case 'ENTRIES_UPLOADED': {
      const { firstDayOfMonth, yesterday } = getRelevantDates()
      let caloriesConsumedToday = 0
      let moneySpentThisMonth = 0
      for (let i = 0; i < action.entries.length; i++) {
        const entry = action.entries[i]
        const entryDate = new Date(entry.date)

        if (entryDate >= yesterday) {
          caloriesConsumedToday += Number(entry.calories)
        }
        if (entryDate >= firstDayOfMonth) {
          moneySpentThisMonth += Number(entry.price)
        }
      }
      return Object.assign({}, state, {
        entries: sortEntries(action.entries),
        caloriesConsumedToday,
        moneySpentThisMonth,
      })
    }
    case 'ENTRIES_UPLOAD_FAILED': {
      return state
    }

    case 'ENTRY_DELETED': {
      if (!state.entries.length) return state
      const entries = [...state.entries]

      const entry = action.deletedEntry
      const { entryId, price, calories } = entry
      const date = new Date(entry.date)
      const removeEntryFilter = (entry) => {
        if (entry.entryId !== entryId) return entry
      }

      const { firstDayOfMonth, yesterday } = getRelevantDates()
      const caloriesConsumedToday =
        state.caloriesConsumedToday - (date > yesterday ? calories : 0)
      const moneySpentThisMonth =
        state.moneySpentThisMonth - (date > firstDayOfMonth ? price : 0)
      return Object.assign({}, state, {
        caloriesConsumedToday,
        moneySpentThisMonth,
        entries: entries.filter(removeEntryFilter),
      })
    }

    case 'ENTRY_CREATED': {
      const entries = [...state.entries]
      const createdEntry = action.entry

      const { firstDayOfMonth, yesterday } = getRelevantDates()
      entries.push(createdEntry)
      sortEntries(entries)
      const newState = { entries }

      const createdEntryDate = new Date(createdEntry.date)

      if (createdEntryDate >= yesterday)
        newState.caloriesConsumedToday =
          state.caloriesConsumedToday + Number(createdEntry.calories)
      if (createdEntryDate >= firstDayOfMonth)
        newState.moneySpentThisMonth =
          state.moneySpentThisMonth + Number(createdEntry.price)

      return Object.assign({}, state, newState)
    }

    case 'ENTRY_EDIT_SUCCESS': {
      if (!state.entries.length) return state

      const entries = [...state.entries]
      const { newEntry, entryEdit } = action
      let oldEntry
      for (let i = 0; i < entries.length; i++) {
        if (entries[i].entryId === newEntry.entryId) {
          oldEntry = entries[i]
          entries[i] = newEntry
        }
      }

      const newState = { entries: sortEntries(entries) }
      const { firstDayOfMonth, yesterday } = getRelevantDates()

      const newEntryDate = new Date(newEntry.date)
      const oldEntryDate = new Date(oldEntry.date)
      if (entryEdit.price) {
        const toAdd = entryEdit.price - oldEntry.price

        if (
          newEntryDate >= firstDayOfMonth &&
          oldEntryDate >= firstDayOfMonth
        ) {
          newState.moneySpentThisMonth = state.moneySpentThisMonth + toAdd
        } else if (newEntryDate >= firstDayOfMonth) {
          newState.moneySpentThisMonth =
            state.moneySpentThisMonth + newEntry.price
        } else if (oldEntryDate >= firstDayOfMonth) {
          newState.moneySpentThisMonth =
            state.moneySpentThisMonth - oldEntry.price
        }
      }

      if (entryEdit.calories) {
        const toAdd = entryEdit.calories - oldEntry.calories

        if (newEntryDate >= yesterday && oldEntryDate >= yesterday) {
          newState.caloriesConsumedToday = state.caloriesConsumedToday + toAdd
        } else if (newEntryDate >= yesterday) {
          newState.caloriesConsumedToday =
            state.caloriesConsumedToday + newEntry.calories
        } else if (oldEntryDate >= yesterday) {
          newState.caloriesConsumedToday =
            state.caloriesConsumedToday - oldEntry.calories
        }
      }
      return Object.assign({}, state, newState)
    }

    default: {
      return state
    }
  }
}

export default entries
