import React from 'react'

const ProgressBar = ({ backgroundColor, progress = 100, total }) => {
  const percent = progress >= total ? 100 : Math.round((100 * progress) / total)
  const progressBarFillStyle = {
    width: `${percent}%`,
    backgroundColor,
  }

  return (
    <div style={{ backgroundColor: 'whitesmoke' }} className="ProgressBar">
      <div style={progressBarFillStyle} className="ProgressBarFill">
        <span className="ProgressText">{`${progress}/${total}`}</span>
      </div>
    </div>
  )
}

export default ProgressBar
