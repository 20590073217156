import FoodTrackerAPI from '../api'

export default function deleteUser({ token, sub }) {
  return async (dispatch) => {
    try {
      await FoodTrackerAPI.deleteUser({ token, sub })
      dispatch({ type: 'USER_DELETED', sub })

      return true
    } catch (error) {
      dispatch({ type: 'USER_DELETE_FAILED' })
      return false
    }
  }
}
