import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'

const SignUp = ({ token, sub, createUser, getUser, user }) => {
  const { user: Auth0User = { name: '' } } = useAuth0()
  const [name, setName] = useState(Auth0User.name)
  useEffect(() => {
    const checkForUser = async () => {
      const userExists = await getUser({ token, sub })
      if (userExists) window.location = '/'
    }

    if (!(token || sub)) {
      window.location = '/'
    }
    if (!user.name) {
      checkForUser()
    }
  })
  const onSubmit = async () => {
    const userCreated = await createUser({ token, sub, name })
    if (userCreated) window.location = '/'
  }
  const Icon = () => (
    <div className="SignUpBoxIconDiv">
      <img className="SignUpBoxIconDivImg" src={`fruit.png`} alt="coder logo" />
    </div>
  )

  const NameForm = () => (
    <div className="NameForm">
      <div className="NameFormInput">
        <p className="NameFormLabel">Username</p>&nbsp;
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        &nbsp;
        <button className="NameFormButton" type="submit" onClick={onSubmit}>
          Create Account
        </button>
      </div>
    </div>
  )
  return (
    <div className="SignUp">
      <div className="SignUpBox">
        <h2>Food Tracker</h2>
        <Icon />
        {NameForm()}
      </div>
    </div>
  )
}

export default SignUp
