import React, { useState, useEffect } from 'react'
import DateTimePicker from 'react-datetime-picker'
const EntryForm = ({
  action,
  onClickThunk,
  entry = {
    name: '',
    price: 0,
    calories: 0,
    date: new Date(),
  },
}) => {
  const [foodName, setFoodName] = useState('')
  const [price, setPrice] = useState(0)
  const [calories, setCalories] = useState(0)
  const [date, setDate] = useState(new Date())
  useEffect(() => {
    if (entry.name) {
      setFoodName(entry.name)
      setPrice(entry.price)
      setDate(new Date(entry.date))
      setCalories(entry.calories)
    }
  }, [entry])

  const buttonAction = action === 'edit' ? 'Edit Entry' : 'Add Entry'

  return (
    <div className="EntryFormBox">
      <div className="EntryFormBoxProperty">
        <label>Food/Meal Name:</label>&nbsp;
        <input
          key="MyFormFood"
          type="text"
          value={foodName}
          onChange={(e) => setFoodName(e.target.value)}
        ></input>
      </div>
      <div>
        <label>Price:</label>&nbsp;
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(Number(e.target.value))}
        ></input>
      </div>
      <div>
        <label>Calories:</label>&nbsp;
        <input
          type="number"
          value={calories}
          onChange={(e) => setCalories(Number(e.target.value))}
        ></input>
      </div>
      <div>
        <label>Date:</label>&nbsp;
        <DateTimePicker onChange={setDate} value={date} />
      </div>
      <button
        type="submit"
        onClick={onClickThunk(foodName, price, calories, date)}
      >
        {buttonAction}
      </button>
    </div>
  )
}

export default EntryForm
