import FoodTrackerAPI from '../api'

export default function changeUserLimit({ token, limitChange, sub }) {
  return async (dispatch) => {
    try {
      await FoodTrackerAPI.changeUserLimit({ limitChange, sub, token })
      dispatch({ type: 'USER_LIMIT_CHANGED', limitChange })

      return true
    } catch (error) {
      dispatch({ type: 'USER_LIMIT_CHANGE_FAILED' })
      return false
    }
  }
}
