import React from 'react'

const Modal = ({ children, showModal, setShowModal }) => {
  return (
    <div className={`Modal${showModal ? ' Show' : ''}`}>
      <div className="ModalContent">
        <div className="ModalContentClose">
          <span onClick={() => setShowModal(false)}>&times;</span>
        </div>
        {children}
      </div>
    </div>
  )
}

export default Modal
