import FoodTrackerAPI from '../api'

export default function editEntry({
  token,
  entryId,
  entryEdit,
  admin = false,
}) {
  return async (dispatch) => {
    try {
      const { entry: newEntry } = await FoodTrackerAPI.editEntry({
        token,
        entryId,
        entryEdit,
      })
      dispatch({ type: 'ENTRY_EDIT_SUCCESS', newEntry, entryEdit, admin })

      return true
    } catch (error) {
      dispatch({ type: 'ENTRY_EDIT_FAILED' })
      return false
    }
  }
}
