import Loading from './Loading'
import { useAuth0 } from '@auth0/auth0-react'
import User from '../containers/User'
import Admin from '../containers/Admin'
import SignUp from '../containers/SignUp'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useEffect } from 'react'
import Auth0Config from '../config.json'
import LoginPage from './pages/LoginPage'

const App = ({ uploadMetadata, token: receivedToken }) => {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    user,
  } = useAuth0()

  useEffect(() => {
    const getAndUploadMetadata = async () => {
      try {
        const token = await getAccessTokenSilently({
          audience: Auth0Config.audience,
        })
        uploadMetadata({ token, sub: user.sub })
      } catch (e) {}
    }
    if (isAuthenticated && !receivedToken) {
      getAndUploadMetadata()
    }
  })

  if (!isAuthenticated && !isLoading) {
    return <LoginPage />
  }

  if (isLoading || !receivedToken) {
    return <Loading></Loading>
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<User />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
    </Router>
  )
}

export default App
