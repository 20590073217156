import { connect } from 'react-redux'
import Admin from '../components/pages/Admin'
import getAdmin from '../actions/getAdmin'
import deleteEntry from '../actions/deleteEntry'
import deleteUser from '../actions/deleteUser'
import createEntry from '../actions/createEntry'
import editEntry from '../actions/editEntry'

const mapStateToProps = (state) => {
  const {
    userReports,
    adminUploadFailed,
    numberOfEntriesInLastWeek,
    numberOfEntriesWeekBeforeLast,
  } = state.admin
  const { token, sub } = state.app

  return {
    numberOfEntriesInLastWeek,
    numberOfEntriesWeekBeforeLast,
    adminUploadFailed,
    userReports,
    token,
    sub,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAdmin: ({ token, sub }) => dispatch(getAdmin({ token, sub })),
  deleteUser: ({ token, sub }) => dispatch(deleteUser({ token, sub })),
  deleteEntry: ({ token, sub, entry }) =>
    dispatch(deleteEntry({ token, sub, entry, admin: true })),
  createEntry: ({ token, entry }) =>
    dispatch(createEntry({ token, entry, admin: true })),
  editEntry: ({ token, entryId, entryEdit }) =>
    dispatch(editEntry({ token, entryId, entryEdit, admin: true })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(Admin)

export default retVal
