import { connect } from 'react-redux'
import createUser from '../actions/createUser'
import getUser from '../actions/getUser'
import SignUp from '../components/pages/SignUp'

const mapStateToProps = (state) => {
  const { token, sub } = state.app
  const { user } = state.user
  return {
    token,
    sub,
    user,
  }
}

const mapDispatchToProps = (dispatch) => ({
  createUser: ({ token, sub, name }) =>
    dispatch(createUser({ token, sub, name })),
  getUser: ({ token, sub }) => dispatch(getUser({ token, sub })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(SignUp)

export default retVal
