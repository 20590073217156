import FoodTrackerAPI from '../api'

export default function getUser({ token, sub }) {
  return async (dispatch) => {
    try {
      const { user, entries } = await FoodTrackerAPI.getUser({ token, sub })
      dispatch({ type: 'USER_UPLOADED', user })
      dispatch({ type: 'ENTRIES_UPLOADED', entries })
      return true
    } catch (error) {
      dispatch({ type: 'USER_UPLOAD_FAILED' })
      dispatch({ type: 'ENTRIES_UPLOAD_FAILED' })
      return false
    }
  }
}
