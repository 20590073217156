import { connect } from 'react-redux'
import User from '../components/pages/User'
import getUser from '../actions/getUser'
import getAdmin from '../actions/getAdmin'

const mapStateToProps = (state) => {
  const { token, sub } = state.app
  const { user } = state.user
  return {
    token,
    sub,
    user,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUser: ({ token, sub }) => dispatch(getUser({ token, sub })),
  getAdmin: ({ token, sub }) => dispatch(getAdmin({ token, sub })),
})

const retVal = connect(mapStateToProps, mapDispatchToProps)(User)

export default retVal
