const admin = (
  state = {
    userReports: [],
    admin: {},
    numberOfEntriesInLastWeek: 0,
    numberOfEntriesWeekBeforeLast: 0,
    users: {},
    adminUploadFailed: false,
  },
  action,
) => {
  const recomputeAverageCalories = (userReport) => {
    let total = 0
    let count = 0
    const weekAgo = new Date()
    weekAgo.setDate(weekAgo.getDate() - 7)
    userReport.entries.forEach((entry) => {
      const entryDate = new Date(entry.date)
      if (entryDate > weekAgo) {
        total += entry.calories
        count++
      }
    })

    userReport.user.averageCaloriesPerEntryInLastWeek =
      count === 0 ? 0 : total / count
  }
  const recomputeStats = (userReports) => {
    const weekAgo = new Date()
    weekAgo.setDate(weekAgo.getDate() - 7)
    const weekBeforeLast = new Date()
    weekBeforeLast.setDate(weekAgo.getDate() - 14)
    let weekTotal = 0,
      lastWeekTotal = 0

    for (let i = 0; i < userReports.length; i++) {
      const { entries } = userReports[i]
      for (let j = 0; j < entries.length; j++) {
        const entryDate = new Date(entries[j].date)
        if (entryDate >= weekAgo) weekTotal++
        if (entryDate < weekAgo && entryDate >= weekBeforeLast) lastWeekTotal++
      }
    }

    return {
      numberOfEntriesInLastWeek: weekTotal,
      numberOfEntriesWeekBeforeLast: lastWeekTotal,
    }
  }
  switch (action.type) {
    case 'ADMIN_UPLOADED': {
      const users = {}
      for (let i = 0; i < action.admin.userReports.length; i++) {
        const { user, entries } = action.admin.userReports[i]
        users[user.sub] = { user, entries }
      }
      const {
        numberOfEntriesInLastWeek,
        numberOfEntriesWeekBeforeLast,
      } = recomputeStats(action.admin.userReports)
      return Object.assign({}, state, {
        ...action.admin,
        users,
        adminUploadFailed: false,
        numberOfEntriesInLastWeek,
        numberOfEntriesWeekBeforeLast,
      })
    }
    case 'ADMIN_UPLOAD_FAILED': {
      return Object.assign({}, state, { adminUploadFailed: true })
    }

    case 'ENTRY_DELETED': {
      if (!action.admin) return state
      const users = { ...state.users }
      const entry = action.deletedEntry
      const { entryId } = entry
      const removeEntryFilter = (entry) => {
        if (entry.entryId !== entryId) return entry
      }
      users[entry.sub].entries = users[entry.sub].entries.filter(
        removeEntryFilter,
      )
      recomputeAverageCalories(users[entry.sub])
      const {
        numberOfEntriesInLastWeek,
        numberOfEntriesWeekBeforeLast,
      } = recomputeStats(state.userReports)
      const userReports = Object.values(users)

      return Object.assign({}, state, {
        users,
        userReports,
        numberOfEntriesInLastWeek,
        numberOfEntriesWeekBeforeLast,
      })
    }

    case 'ENTRY_CREATED': {
      if (!action.admin) return state

      const createdEntry = action.entry
      const users = { ...state.users }
      const entries = users[createdEntry.sub].entries
      entries.push(createdEntry)
      recomputeAverageCalories(users[createdEntry.sub])
      const userReports = Object.values(users)
      const {
        numberOfEntriesInLastWeek,
        numberOfEntriesWeekBeforeLast,
      } = recomputeStats([...state.userReports])
      return Object.assign({}, state, {
        numberOfEntriesInLastWeek,
        numberOfEntriesWeekBeforeLast,
        users,
        userReports,
      })
    }

    case 'ENTRY_EDIT_SUCCESS': {
      if (!action.admin) return state

      const users = { ...state.users }
      const entry = action.newEntry

      const entries = users[entry.sub].entries

      for (let i = 0; i < entries.length; i++) {
        if (entries[i].entryId === entry.entryId) {
          entries[i] = entry
        }
      }
      recomputeAverageCalories(users[entry.sub])

      const userReports = Object.values(users)
      const {
        numberOfEntriesInLastWeek,
        numberOfEntriesWeekBeforeLast,
      } = recomputeStats(state.userReports)
      return Object.assign({}, state, {
        users,
        userReports,
        numberOfEntriesInLastWeek,
        numberOfEntriesWeekBeforeLast,
      })
    }

    case 'USER_DELETED': {
      const users = { ...state.users }
      delete users[action.sub]

      const userReports = Object.values(users)

      return Object.assign({}, state, { users, userReports })
    }

    default: {
      return state
    }
  }
}

export default admin
